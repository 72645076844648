import React from 'react';
import './ContentSection.css';

function ContentSection({ text, lines, image }) {
  return (
    <div className="content-section">
      <h2>$NWG Tokenomics</h2>
      <div>
        <p>
          NotWifGary [<strong>$NWG</strong>] is a new memecoin launched on 24th May 2024 on Linea, a Layer 2 zkEVM built on Ethereum. With a fair launch, the $NWG is a
          vanilla ERC20 token, deployed from a multisig wallet involving Original Project Supporters (see down for the team info). Key features include:
        </p>
        <ul>
          <li><strong>Total Supply:</strong> 69B tokens.</li>
          <li><strong>Decentralized Funding:</strong> Community-funded ETH liquidity pool via StationX on Linea.</li>
          <li><strong>Donors:</strong> 283 amazing donors contributed 10ETH via StationX on Linea that was used to fund the LPs at launch.</li>
          <li><strong>Locked Liquidity:</strong> 100% locked for 66 years to honor Mr. Gary Gensler’s age.</li>
          <li><strong>Fair Launch Commitments:</strong> No pre-allocated tokens for creator(s), exchanges or market makers, and no minting rights, ensuring a completely fair setup.</li>
          <li><strong>Community Governance:</strong> A 2% tax on DEX trades funds a DAO, transitioning control to the community once $100K in ETH is reached.</li>
        </ul>
        <p>
          $NWG also symbolizes resistance against regulatory overreach, advocating for <strong>#ETHforALL</strong> and supporting those in legal battles with U.S. regulators.
        </p>
      </div>
    </div>

  );
}

export default ContentSection;
