import React from 'react';
import './CTAButton.css';

function CTAButtonTop({ link }) {
  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <a href={link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
        <button style={{ backgroundColor: 'green', color: 'white', fontSize: '20px', padding: '15px 30px' }}>Trade $NWG</button>
      </a>
    </div>
  );
}

export default CTAButtonTop;
