import React from 'react';
import './Project.css';

function Project({ text, lines, image }) {
  return (
    <div className="project-section">
        <h2>$NWG Launch [done]</h2>
        <div>
          <p>
            $NWG champions <strong>maximal decentralization</strong> from the outset. Community contributions via ETH donations
            coming from 283 amazing donors, facilitated the initial liquidity setup on the <strong>Lynex DEX</strong>. Highlights include:
          </p>
          <ul>
            <li><strong>Donation Cap:</strong> 10ETH total, with individual donations capped between 0.01ETH and 0.5ETH.</li>
            <li><strong>Participation Token:</strong> Donors receive a soulbound NFT marking their support, without rights to token allocations at launch.</li>
            <li><strong>Honorable Donors Benefits:</strong> Everyone who donated can access a token gated Telegram Group and participate in the decision process around the project. <a href="https://telegram.me/collablandbot?start=VFBDI1RFTCNDT01NIy0xMDAyMTk0OTUzOTky" target="_blank" rel="noopener noreferrer">Join here</a> if you are an honorable donor.</li>
            <li><strong>Risks:</strong> Engaging with $NWG carries inherent risks, including volatility concerns. The absence of a dedicated market maker may lead to price instability. Additionally, the potential for large-scale purchases immediately after launch could significantly impact token stability.</li>
          </ul>
      </div>
    </div>
  );
}

export default Project;
