const backers =
[
  {
    nickname: "Marco",
    title: "Troublemakoor",
    twitter: "https://twitter.com/marcomonaco83",
    imgname: "marco-pic.png"
  },
  {
    nickname: "Foxy",
    title: "Foxy Founder",
    twitter: "https://twitter.com/FoxyLinea",
    imgname: "foxy-pic.png"
  },
  {
    nickname: "Shama",
    title: "eFrogs Pond Maestro",
    twitter: "https://x.com/NotEnoughEth",
    imgname: "shama-pic.png"
  },
  {
    nickname: "Declan",
    title: "Roadmaps Definoor",
    twitter: "https://twitter.com/DeclanFox14",
    imgname: "declan-pic.png"
  },
  {
    nickname: "ApeGurus",
    title: "Lynex Founder",
    twitter: "https://twitter.com/ApeGurus",
    imgname: "ape-pic.png"
  },
  {
    nickname: "Naut",
    title: "Capitan Starship",
    twitter: "https://twitter.com/0xrishavb",
    imgname: "naut-pic.png"
  },
  {
    nickname: "Ryker",
    title: "ZeroLend Founder",
    twitter: "https://twitter.com/deadshotryker",
    imgname: "ryker-pic.png"
  },
  {
    nickname: "Alex",
    title: "Satoshi Universe Co Founder",
    twitter: "https://twitter.com/alex__mcl",
    imgname: "alex-pic.png"
  },
  {
    nickname: "Mehdi",
    title: "PolygonID",
    twitter: "https://twitter.com/Mehdiaeth",
    imgname: "mehdi-pic.png"
  },
  {
    nickname: "Atomic Goon",
    title: "GOB Co-founder",
    twitter: "https://twitter.com/iam516tv",
    imgname: "memefi-pic.png"
  },
  {
    nickname: "Barna",
    title: "Mendi Founder",
    twitter: "https://twitter.com/realbarnakiss",
    imgname: "barna-pic.png"
  },
  {
    nickname: "Muss",
    title: "Linea Game Smith",
    twitter: "https://twitter.com/Hierony_Mus",
    imgname: "muss-pic.png"
  }
]

export default backers;
