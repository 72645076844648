import React from 'react';
import './Footer.css'; // Make sure your CSS file is linked

function Footer() {
  return (
    <footer className="site-footer">
      <div className="footer-left">
        <p>Join the movement, have fun, free ETH from Gary Gensler!</p>
        <p>© 2024 Insecurity Exchange Chuckmission</p>
        <p>All rights reserved.</p>
      </div>
      <ul className="footer-links">
        <li><a href="https://lineascan.build/token/0xe2a6e74118e708f7652fc4c74d2f9ee5fa210563" target="_blank" rel="noopener noreferrer">Contract</a></li>
        <li><a href="https://lineascan.build/token/0xBDDB36e48f5967aB8B0E0734b1e63c6c34266337" target="_blank" rel="noopener noreferrer">Official Pool</a></li>
        <li><a href="https://twitter.com/NotWifGary" target="_blank" rel="noopener noreferrer">Twitter</a></li>
        <li><a href="https://t.co/C3vnveoKRo" target="_blank" rel="noopener noreferrer">Telegram [Group]</a></li>
        <li><a href="https://t.me/notwifgaryreal" target="_blank" rel="noopener noreferrer">Telegram [Channel]</a></li>
        <li><a href="https://app.lynex.fi/swap?outputCurrency=0xe2a6e74118e708f7652fc4c74d2f9ee5fa210563" target="_blank" rel="noopener noreferrer">Lynex</a></li>
        <li><a href="https://app.stationx.network/dashboard/0x4770Aae9Be0117Df6020EC8ef234540b681f3210" target="_blank" rel="noopener noreferrer">StationX</a></li>
      </ul>
    </footer>
  );
}

export default Footer;
