import React from 'react';
import Slider from 'react-slick';
import './Carousel.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import csiLogo from '../assets/img/consensys-logo.png';
import cbLogo from '../assets/img/coinbase-logo.png';
import uniLogo from '../assets/img/uniswap-logo.png';
import rhLogo from '../assets/img/rh-logo.png';
import whosnext from '../assets/img/whosnext.png';

function Carousel({ type }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
  };

  return (
    <div className="carousel">
      <h2>What Overreach looks like</h2>
      <Slider {...settings}>
        <a href="https://assets.ctfassets.net/gjyjx7gst9lo/2kfQoAKoQyQD1cw0HbVF3I/9c52c1e8754583c8f9b090e4b610de65/Consensys_v._Gensler_et_al.__Complaint_for_Declaratory_and_Injunctive_Relief__as-filed_.pdf" target="_blank" rel="noopener noreferrer">
          <div className="carousel-elem">
            <h3>Consensys Lawsuit</h3>
            <div className="content-row">
              <p>
                <span style={{display: 'block', marginBottom: '20px'}}>Consensys Sues the SEC to Defend the U.S. Ethereum Community.</span>
                <span style={{display: 'block', marginBottom: '20px'}}>The U.S. Securities and Exchange Commission’s (SEC) threatened regulation of ether as a security would jeopardize the United States’ ability to use Ethereum and similar blockchain technology.</span>
              </p>
              <img src={csiLogo} alt="Action Element"/>
            </div>
          </div>
        </a>
        <a href="https://www.reuters.com/business/finance/robinhood-crypto-gets-wells-notice-us-sec-2024-05-06/" target="_blank" rel="noopener noreferrer">
        <div className="carousel-elem">
          <h3>Robin Hood Wells Notice</h3>
          <div className="content-row">
            <p>
              <span style={{display: 'block', marginBottom: '20px'}}>Robinhood Receives Wells Notice from the SEC.</span>
              <span style={{display: 'block', marginBottom: '20px'}}>Robinhood received a Wells Notice from the SEC concerning its U.S. crypto business. The company  notes that the SEC has not provided clear guidelines on what constitutes a security in the crypto space. Robinhood emphasized the products at question are NOT securities.</span>
            </p>
            <img src={rhLogo} alt="Action Element"/>
          </div>
        </div>
        </a>
        <a href="https://www.wsj.com/livecoverage/cpi-report-today-inflation-stock-market-04-10-2024/card/sec-warns-defi-firm-uniswap-labs-of-potential-lawsuit-WBbtKQFAkh12I28Ds4fm" target="_blank" rel="noopener noreferrer">
        <div className="carousel-elem">
          <h3>Uniswap Wells Notice</h3>
          <div className="content-row">
            <p>
              <span style={{display: 'block', marginBottom: '20px'}}>SEC issues Uniswap a Wells Notice</span>
              <span style={{display: 'block', marginBottom: '20px'}}>The SEC issued a Wells notice to Uniswap on April 10, 2024, indicating potential enforcement action. Uniswap, a decentralized exchange, operates on principles that avoid traditional intermediaries, utilizing AMMs on the Ethereum and other blockchains. This Wells notice is significant as it represents the SEC's continued scrutiny of decentralized finance (DeFi) platforms.</span>
            </p>
            <img src={uniLogo} alt="Action Element"/>
          </div>
        </div>
        </a>
        <a href="https://www.sec.gov/files/litigation/complaints/2023/comp-pr2023-102.pdf" target="_blank" rel="noopener noreferrer">
        <div className="carousel-elem">
          <h3>Coinbase Lawsuit</h3>
          <div className="content-row">
            <p>
              <span style={{display: 'block', marginBottom: '20px'}}>SEC Sues Coinbase for Securities Violations.</span>
              <span style={{display: 'block', marginBottom: '20px'}}>The U.S. Securities and Exchange Commission (SEC) has filed a lawsuit against Coinbase, alleging that the crypto exchange has been operating without proes per registration as a national securitiexchange, bleroker, and caring agency since at least 2019. The lawsuit specifically targets Coinbase's crypto asset staking-as-a-service program, claiming that it involves securities that should have been registered under U.S. securities laws.</span>
            </p>
            <img src={cbLogo} alt="Action Element"/>
          </div>
        </div>
        </a>
        <div className="carousel-elem">
          <h3>Who's next?</h3>
          <div className="content-row">
            <p>
              <span style={{display: 'block', marginBottom: '20px'}}>Who will be the next on the line?</span>
              <span style={{display: 'block', marginBottom: '20px'}}>The U.S. Securities and Exchange Commission’s (SEC) will very likely continue and improve their oppressive outreach, eventually moving the target from Crypto Firms to individual developers of EIPs, etc.</span>
            </p>
            <img src={whosnext} alt="Action Element"/>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default Carousel;
