import React from 'react';
import HeroSection from './components/HeroSection';
import IntroSection from './components/IntroSection';
import Carousel from './components/Carousel';
import Backers from './components/Backers';
import ContentSection from './components/ContentSection';
import Project from './components/Project';
import NWGInfo from './components/NWGInfo';
import CTAButtonTop from './components/CTAButtonTop';
import Footer from './components/Footer';
import './App.css';
import backers from './assets/backers-content.js';

function App() {
  return (
    <div>
      <HeroSection/>
      <CTAButtonTop link="https://app.lynex.fi/swap?outputCurrency=0xe2a6e74118e708f7652fc4c74d2f9ee5fa210563" />
      <IntroSection/>
      <Carousel/>
      <ContentSection />
      <Project />
      <NWGInfo />
      <Backers backers={backers}/>
      <Footer />
    </div>
  );
}

export default App;
