import React from 'react';
import './IntroSection.css';

function IntroSection({ text, lines, image }) {
  return (
    <div className="intro-section">
        <p className="carousel">Gary Gensler and the SEC are trying to regulate Ethereum far beyond their legal mandate. Open source software cannot be regulated as a security, and open-source developers should not work in fear of government punishment for contributing to open source code.</p>
        <p className="carousel">We stand with crypto organizations who are at the forefront legal battles defending our digital property rights and voice what we want:</p>
        <p className="carousel" style= {{fontSize: '2em'}} ><strong>Ethereum open to everyone!</strong></p>
    </div>
  );
}

export default IntroSection;
