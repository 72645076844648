import React, { useState } from 'react';
import Modal from 'react-modal';
import './NWGInfo.css';  // Make sure to create and include styles for NWGInfo
import ModalContent from './ModalContent';
import nwgLogo from '../assets/img/nwg-logo.png';
import { BridgeAndSwap } from "onthis-library";

Modal.setAppElement('#root'); // Improves accessibility by setting the root app element for screen readers

function NWGInfo() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const openModal = (content) => {
    setModalContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#000',
      color: '#0f0',
      padding: '20px',
      borderRadius: '10px',
      width: '80%',
      maxWidth: '500px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    }
  };

  // const lynexModal = {
  //   title: 'Buy on Lynex DEX',
  //   content1: 'Step 1: Bridge funds to Linea <a href="https://bridge.linea.build" target="_blank" rel="noopener noreferrer">here</a> or using MetaMask Brige or send ETH from Ethereum Mainnet to bridge.onlinea.eth',
  //   address: '',
  //   content2: 'Step 2: <a href="https://app.lynex.fi/swap?outputCurrency=0xe2a6e74118e708f7652fc4c74d2f9ee5fa210563" target="_blank" rel="noopener noreferrer">buy NWG on Lynex DEX</a>',
  //   footer: ''
  // }

  // const onthisModal = {
  //   title: 'Use an Onthis.xyz Shortcut',
  //   content1: 'From your Linea wallet, send LineaETH to this contract:',
  //   address: '0xe88385c15c8E8b4Ad8C5fFf934b0892cA3e17216',
  //   content2: '100% of ETH sent there will be automatically converted in $NWG',
  //   footer: 'Process will take 3min on average. Third party fees applies, see more on <a href="https://docs.onthis.xyz/overview/users/fee-breakdown" target="_blank" rel="noopener noreferrer">official docs</a>'
  // }

  const onthisconfig = {
    name: "NWG",
    logo: nwgLogo, // Project logo, suggested resolution 800x800 pixels.
    web3modalProjectId: "5f4d76ce23c0e0de94fe2ccb1c5b5d85", // We will provide this.
    rounded: true,
    primaryColor: "#000", // change to match your branding.
    secondaryColor: "#0f0", // change to match your branding.
    chainId: 59144,
    tokenAddress: "0xe2a6e74118e708f7652fc4c74d2f9ee5fa210563"
  }

  // const mainnetModal = {
  //   title: 'Buy from Ethereum Mainnet',
  //   content1: 'From your wallet on Ethereum Mainnet, send ETH to:',
  //   address: 'buy.notwifgary.eth',
  //   content2: 'to bridge ETH to Linea and convert 100% of ETH sent in $NWG.',
  //   footer: 'Plese send only ETH, only from Ethereum Mainnet or you will lose your assets. Process will take 3min on average. Third party fees applies, see more on <a href="https://docs.onthis.xyz/overview/users/fee-breakdown" target="_blank" rel="noopener noreferrer">official docs</a>'
  // }
  //
  // const baseModal = {
  //   title: 'Buy from Base Network',
  //   content1: 'From your wallet on Base, send ETH to:',
  //   address: '0xe6C44925217d04DaBE984737342824e7aD0f9a02',
  //   content2: 'to bridge ETH to Linea and convert 100% of ETH sent in $NWG.',
  //   footer: 'Plese send only ETH, only from Base Network or you will lose your assets. Process will take 3min on average. Third party fees applies, see more on <a href="https://docs.onthis.xyz/overview/users/fee-breakdown" target="_blank" rel="noopener noreferrer">official docs</a>'
  // }
  //
  // const arbModal = {
  //   title: 'Buy from Arbitrum One',
  //   content1: 'From your wallet on Arbitrum One, send ETH to:',
  //   address: '0x99B0F04E53Bd1A890D0Dfd49460341c9E3e9fdBF',
  //   content2: 'to bridge ETH to Linea and convert 100% of ETH sent in $NWG.',
  //   footer: 'Plese send only ETH, only from Arbitrum One or you will lose your assets. Process will take 3min on average. Third party fees applies, see more on <a href="https://docs.onthis.xyz/overview/users/fee-breakdown" target="_blank" rel="noopener noreferrer">official docs</a>'
  // }
  //
  // const opModal = {
  //   title: 'Buy from Optimism',
  //   content1: 'From your wallet on Optimism, send ETH to:',
  //   address: '0xbC385Ced5017e3Daf6DB8eab853Dbc78C9e7E102',
  //   content2: 'to bridge ETH to Linea and convert 100% of ETH sent in $NWG.',
  //   footer: 'Plese send only ETH, only from Optimism Network or you will lose your assets. Process will take 3min on average. Third party fees applies, see more on <a href="https://docs.onthis.xyz/overview/users/fee-breakdown" target="_blank" rel="noopener noreferrer">official docs</a>'
  // }
  //
  // const polygonModal = {
  //   title: 'Buy from Polygon PoS',
  //   content1: 'From your wallet on Polygon, send MATIC to:',
  //   address: '0xB42eE00Fb7812Bc3F0D3A184d7FCc5AF6979E888',
  //   content2: 'to bridge MATIC to Linea and convert 100% of ETH sent in $NWG.',
  //   footer: 'Plese send only MATIC, only from Polygon PoS or you will lose your assets. Process will take 3min on average. Third party fees applies, see more on <a href="https://docs.onthis.xyz/overview/users/fee-breakdown" target="_blank" rel="noopener noreferrer">official docs</a>'
  // }

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h2>$NWG Token Info and Links</h2>
      <p className="section">
        <a href="https://lineascan.build/token/0xe2a6e74118e708f7652fc4c74d2f9ee5fa210563" target="_blank" rel="noopener noreferrer">
          Official Contract Account: 0xe2a6e74118e708f7652fc4c74d2f9ee5fa210563
        </a>
      </p>

      <div className="section community">
        <h3>Community:</h3>
        <div className="link-item">
          <a href="https://x.com/NotWifGary" target="_blank" rel="noopener noreferrer">
            <img src={require('../assets/img/icons/twitter.png')} alt="X" className="element" />
            <span>X Profile</span>
          </a>
        </div>
        <div className="link-item">
          <a href="https://t.co/C3vnveoKRo" target="_blank" rel="noopener noreferrer">
            <img src={require('../assets/img/icons/telegram.png')} alt="Telegram Group" className="element" />
            <span>Telegram [Group]</span>
          </a>
        </div>
        <div className="link-item">
          <a href="https://t.me/notwifgaryreal" target="_blank" rel="noopener noreferrer">
            <img src={require('../assets/img/icons/telegram.png')} alt="Telegram Channel" className="element" />
            <span>Telegram [Channel]</span>
          </a>
        </div>
        <div className="link-item">
          <a href="https://warpcast.com/~/channel/notwifgary" target="_blank" rel="noopener noreferrer">
            <img src={require('../assets/img/icons/farcaster.png')} alt="Farcaster" className="element" />
            <span>Farcaster Channel</span>
          </a>
        </div>
        <div className="link-item">
          <a href="https://debank.com/profile/0xe0df75542e3b17613c118645b80c75a448ff4e65" target="_blank" rel="noopener noreferrer">
            <img src={require('../assets/img/icons/debank.png')} alt="DeBank" className="element" />
            <span>DeBank Profile</span>
          </a>
        </div>
      </div>

      <div className="section trading">
        <h3>Trading:</h3>
        <div className="link-item">
          <a href="https://app.lynex.fi/swap?outputCurrency=0xe2a6e74118e708f7652fc4c74d2f9ee5fa210563" target="_blank" rel="noopener noreferrer">
            <img src={require('../assets/img/icons/lynex.png')} alt="Lynex Official Pool" />
            <span>Official DEX Pool</span>
          </a>
        </div>
        <div className="link-item">
          <a href="https://www.coingecko.com/en/coins/notwifgary" target="_blank" rel="noopener noreferrer">
            <img src={require('../assets/img/icons/coingeko.png')} alt="CoinGeko" />
            <span>Coingeko</span>
          </a>
        </div>
        {/*
        //
        // <div className="link-item">
        //   <a href="https://coinmarketcap.com/dexscan/linea/0xbddb36e48f5967ab8b0e0734b1e63c6c34266337/" target="_blank" rel="noopener noreferrer">
        //     <img src={require('../assets/img/icons/cmc.png')} alt="CoinMarketCap" />
        //     <span>CoinMarketCap</span>
        //   </a>
        // </div>
        */}
        <div className="link-item">
          <a href="https://www.dextools.io/app/en/linea/pair-explorer/0xbddb36e48f5967ab8b0e0734b1e63c6c34266337?t=1716594816534" target="_blank" rel="noopener noreferrer">
            <img src={require('../assets/img/icons/dextools.png')} alt="DexTools" />
            <span>DexTools</span>
          </a>
        </div>
        <div className="link-item">
          <a href="https://dexscreener.com/linea/0xbddb36e48f5967ab8b0e0734b1e63c6c34266337" target="_blank" rel="noopener noreferrer">
            <img src={require('../assets/img/icons/dexscreener.png')} alt="DexScreener" />
            <span>DexScreener</span>
          </a>
        </div>
      </div>

      <div className="section buy">
        <h3>Buy $NWG on Linea:</h3>
        <div className="link-item">
          <a href="https://app.lynex.fi/swap?outputCurrency=0xe2a6e74118e708f7652fc4c74d2f9ee5fa210563" target="_blank" rel="noopener noreferrer">
            <img src={require('../assets/img/icons/lynex.png')} alt="Lynex Official Pool" />
            <span>Lynex DEX</span>
          </a>
        </div>
        {/*
        // <div className="link-item">
        //   <a onClick={() => openModal('Open MetaMask, click on Swap, select your source chain and currency and select Linea and NWG as destination chain and token')}>
        //     <img src={require('../assets/img/icons/metamask.png')} alt="Metamask Swaps" />
        //     <span>MetaMask Swaps</span>
        //   </a>
        // </div>
        */
        // <div className="link-item">
        //   <a onClick={() => openModal(<ModalContent address={onthisModal.address} title={onthisModal.title} content1={onthisModal.content1} content2={onthisModal.content2} footer={onthisModal.footer} />)}>
        //     <img src={require('../assets/img/icons/onthis.png')} alt="OnThis.xyz Shortcut" />
        //     <span>OnThis.xyz</span>
        //   </a>
        // </div>
        }
      </div>

      <div className="section buy">
        <h3>Buy $NWG from other Networks:</h3>
        <div className="buy-widget">
          <BridgeAndSwap config={onthisconfig} />
        </div>
        <div className="buy-desc">
          <p><b>Supported Network</b>: Linea, Ethereum Mainnet, Base, Optimism, Arbitrum, Polygon</p>
          <p><b>Supported Tokens</b>: ETH, MATIC (only from Polygon PoS)</p>
          <p>Process will take 3min on average. Third party fees applies, see more on <a href="https://docs.onthis.xyz/overview/users/fee-breakdown" target="_blank" rel="noopener noreferrer">official docs</a></p>
        </div>
        {

        // <div className="link-item">
        //   <a onClick={() => openModal(<ModalContent title={mainnetModal2.title} config={onthisconfig} footer={mainnetModal2.footer} />)}>
        //     <img src={require('../assets/img/icons/ethereum.png')} alt="Mainnet Shortcut" />
        //     <span>Buy $NWG from Ethereum Mainnet</span>
        //   </a>
        // </div>
        // <div className="link-item">
        //   <a onClick={() => openModal(<ModalContent address={baseModal.address} title={baseModal.title} content1={baseModal.content1} content2={baseModal.content2} footer={baseModal.footer} />)}>
        //     <img src={require('../assets/img/icons/base.png')} alt="Base Shortcut" />
        //     <span>Buy $NWG from Base Network</span>
        //   </a>
        // </div>
        // <div className="link-item">
        //   <a onClick={() => openModal(<ModalContent address={arbModal.address} title={arbModal.title} content1={arbModal.content1} content2={arbModal.content2} footer={arbModal.footer} />)}>
        //     <img src={require('../assets/img/icons/arbitrum.png')} alt="Arbitrum Shortcut" />
        //     <span>Buy $NWG from Arbitrum</span>
        //   </a>
        // </div>
        // <div className="link-item">
        //   <a onClick={() => openModal(<ModalContent address={opModal.address} title={opModal.title} content1={opModal.content1} content2={opModal.content2} footer={opModal.footer} />)}>
        //     <img src={require('../assets/img/icons/optimism.png')} alt="OP Shortcut" />
        //     <span>Buy $NWG from Optimism</span>
        //   </a>
        // </div>
        // <div className="link-item">
        //   <a onClick={() => openModal(<ModalContent address={polygonModal.address} title={polygonModal.title} content1={polygonModal.content1} content2={polygonModal.content2} footer={polygonModal.footer} />)}>
        //     <img src={require('../assets/img/icons/polygon.png')} alt="Polygon Shortcut" />
        //     <span>Buy $NWG from Polygon PoS</span>
        //   </a>
        // </div>
        }
      </div>

      <div className="section donors">
        <h3>Honorable donors:</h3>
        <div className="link-item">
          <a href="https://telegram.me/collablandbot?start=VFBDI1RFTCNDT01NIy0xMDAyMTk0OTUzOTky" target="_blank" rel="noopener noreferrer">
            <img src={require('../assets/img/icons/telegram.png')} alt="Donors' Group" />
            <span>Donors' Group</span>
          </a>
        </div>
        <div className="link-item">
          <a href="https://app.stationx.network/dashboard/0x4770Aae9Be0117Df6020EC8ef234540b681f3210" target="_blank" rel="noopener noreferrer">
            <img src={require('../assets/img/icons/stationx.png')} alt="StationX" />
            <span>StationX</span>
          </a>
        </div>
      </div>

      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        {modalContent}
        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
}

export default NWGInfo;
